function onBeforLogin() {
    var userid = $.trim($("#userid").val());
    if (!userid) {
        KYWarn(kygMsg(320777, '登录账户不能为空'), kygMsg(101000, '登录'), function () { focusInput(); });
        return false;
    }
    sessionStorage.setItem('login_account', userid)
    if (window.rsaPwd) rsaPwd()
    return true;
}

function setVCodeUrl() {
    if (!$('#vcode_box').is(':hidden')) {
        $('#vcode_img').attr('src', $x.appRoot() + 'Tools/Others/vCode.aspx?r=' + Math.random());
    }
}

function toggleSmsCode() {
    var config = self._needSmsLoginBySp;
    if (config == 'bysp') {
        var pars = 'account=' + $('#userid').val() + '&fp=' + ($('#fingerprint').val() || 'default');
        loginAjax('needSmsCode', pars, function (need) {
            $('#vcode_box1').toggle(need)
        })
    } else {
        $('#vcode_box1').toggle(!!config)
    }
}

function loginAjax(action, params, onOk) {
    $.ajax({
        type: 'POST',
        url: $x.appRoot() + 'Tools/Others/SendSmsCode.aspx?action=' + action + '&' + params,
        data: '',
        processData: false,
        contentType: false,
        success: function (retObj) {
            if (retObj.msgid != 0) {
                if (retObj.msg) alert(retObj.msg);
            } else {
                onOk(retObj.data)
            }
        }
    })
}
function sendVCode() {
    if (!$('#vcode_box').is(':hidden')) {
        loginAjax('', 'account=' + $('#userid').val(), function () {
            startcount();
        });
    }

    function startcount() {
        var sec = $('#smsvcode').attr('smsExpireSec');
        $('#smsvcode').prop('readonly', false).val('').focus();
        var button = $('#sendbtn')
        button.prop('disabled', true);
        next();

        function next() {
            button.val('(' + sec + ')' + kygMsg(311537, '秒'));
            sec--;
            if (sec !== 0) setTimeout(next, 1000);
            else {
                button.prop('disabled', false).val(kygMsg(321763, '获取验证码'));
            }
        }
    }
}


function editUserPwd(ess) {
    $x.top().location.href = $x.appRoot() + 'Tools/Others/GetBackPassword.aspx?ess=' + ess;
}


function loginEnter(e) {
    var code = window.event ? e.keyCode : e.which;
    if (code == 13) {
        $('#usrlogin').trigger('click');
    }
}

function setCookie(name, value) {
    var path = "/";
    var date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + date.toGMTString() + ((path) ? "; path=" + path : "");
}

function loginInit() {
    var userId = getUserId()
    setCookie("screen-with", $(window).width());
    if (userId) {
        $('#userid').prop('readonly', true);
    }
    focusInput(userId);
    if (!userId) userId = sessionStorage.getItem('login_account') || '';
    $('#userid').val(userId);
    var lgid = $(".language li.active a").attr('lgid')
    if (lgid) setCookie("lgid", lgid);
    //获取客户端指纹
    FingerprintJS.load().then(function (fp) { return fp.get() }).then(function (r) {
        $('#fingerprint').val(r.visitorId);
        toggleSmsCode()
    })
}
function getUserId() {
    var userId = $.trim($x.queryString("userid"));
    if (!userId) userId = $.trim($x.queryString('account'));
    return userId
}

function focusInput(focusPwd) {
    var focusId = focusPwd ? '#auth' : '#userid'
    setTimeout(function () {
        if (!$('#dialog-layer')[0]) $(focusId).focus()
    }, 50);
}

function dingDingScanCodeTips() {
    var ddType = '';
    var name = 'needBindAccount';
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var targetURL = window.location.search;
    var data = targetURL.substr(1).match(reg);
    if (data != null) { ddType = unescape(data[2]); }

    if (ddType === '1') {
        KYAlert(kygMsg(320778, '你好，扫码成功！系统发现您还未绑定登陆账号，请用账户密码登录，绑定一次即可！'), kygMsg(320591, '钉钉扫码登陆'));
    }
    else if (ddType === '2') {
        KYAlert(kygMsg(320779, '对不起，获取扫码用户信息失败！'), kygMsg(320591, '钉钉扫码登陆'));
    }
}

function changeLang(lgid) {
    var href = location.href.replace(/[?&]lgid=\d+/g, '');
    var joiner = href.indexOf('?') == -1 ? '?' : '&';
    setCookie("lgid", lgid);
    location.href = href + joiner + 'lgid=' + lgid;
}